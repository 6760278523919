import React, { Component } from "react";
import axios from 'axios';

class IGPosts extends Component {
    state = {
        postImgScr: null,
        rendered: false,
    }
    
    getPosts = () => {
        axios.get('https://graph.facebook.com/v9.0/instagram_oembed?url=https://www.instagram.com/p/' + this.props.postID + '/&maxwidth=320&fields=thumbnail_url%2Cauthor_name%2Cprovider_name%2Cprovider_url&access_token=328716275162649|ddcd1dbc601414300066c7a7175e6ba8')
            // // axios.get('https://graph.facebook.com/v9.0/17841430219274315?fields=instagram_business_account&access_token=328716275162649|ddcd1dbc601414300066c7a7175e6ba8')
            .then(res => {
                this.setState({ postImgScr: res.data.thumbnail_url, rendered: true })
                console.log(res);
                // console.log(this.state.postImgScr);
            })
            .catch(err => {
                console.log(err);
            })
        //     axios.get("https://graph.facebook.com/v3.2/17841430219274315?fields=business_discovery.username(riannebil_groenenstyling){followers_count,media_count,media}&access_token=328716275162649|ddcd1dbc601414300066c7a7175e6ba8")
        //     .then(res => {
        //         console.log(res);
        //     })
        //     .catch(err => {
        //         console.log(err);
        //     })
        // }
    }
    render() {
        if (this.state.rendered) {
            return (
                <img src={this.state.postImgScr} alt='laatste bericht' />
            )
        }
        this.getPosts();
        return null;
    }
}

export default IGPosts;