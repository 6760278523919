import React from 'react';
import emailjs from 'emailjs-com';

import classes from './Form.module.css';

export default function ContactUs() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_thcyngg', 'template_wyt1b3l', e.target, 'user_MSXMU8sBbwIy99onMFcmW')
      .then((result) => {
          console.log(result.text);
          window.location.reload();
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
    <form className={classes.Contact} onSubmit={sendEmail}>
      <input type="hidden" name="contact_number" />
      <label>Naam</label>
      <input type="text" name='from_name' required />
      <label>Email</label>
      <input type="email" name="email" required />
      <label>Bericht</label>
      <textarea name="message" required />
      <input type="submit" value="Verzend" className={classes.Submit}/>
    </form>
  );
}
