import React, { Component } from 'react';
// import { Redirect, Route, Switch } from 'react-router-dom';
import Home from './Container/Home/Home';

class App extends Component {
  render() {
    return (
      <Home />
    )
  }
}

export default App;