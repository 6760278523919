import React from 'react';
import foto1 from '../../assets/images/foto1.jpeg';
import foto2 from '../../assets/images/foto2.jpeg';
import foto3 from '../../assets/images/foto3.jpeg';

const photos = props => {
    const getPhoto = () => {
        switch (props.src) {
            case 'foto1':
                return <img src={foto1} alt='bank' />
            case 'foto2':
                return <img src={foto2} alt='openhaard' />
            case 'foto3':
                return <img src={foto3} alt='theekopjes' />
            default:
                return null;
        }
    }
    return (
        <div>
            {getPhoto()}
        </div>
    )
}

export default photos;