import classes from './Home.module.css';
import React, { Component } from 'react';
import Form from '../../components/Form/Form';
import logo from '../../assets/images/Rianlogo.png';
import IGPosts from '../../components/IGPosts/IGPosts';
import Photos from '../../components/photos/photos';

class Home extends Component {

  state = {
    formShown: false
  }
  formRenderHandler = () => {
    this.setState({ formShown: !this.state.formShown })
  }
  render() {
    return (
      <>
        <header>
          <nav className={classes.Nav}>
            <img src={logo} alt="logo" className={classes.Logo} /><span>Rianne Bil</span>
          </nav>
        </header>
        <div className={classes.App}>
          <h1>Welkom op mijn website</h1>
          {/* <p><a href="mailto:info@riannebil.nl">Klik hier om met ons in contact te komen.</a></p> */}
          <div className={classes.Post1}>
            {/* <IGPosts postID='CK5x8T8DvfN' /> */}
            <Photos src='foto1' />
          </div>
          <div className={classes.Post2}>
            {/* <IGPosts postID='CK4VCijDzAZ' /> */}
            <Photos src='foto2' />
          </div>
          <footer className={classes.Footer}>
            <div>
              {this.state.formShown ? <Form /> : null}
              {/* <p onClick={this.formRenderHandler} className={classes.Link}>Klik hier om met ons in contact te komen.</p> */}
              <span>Kom in contact via de volgende platforms:
                <div><a href="https://www.instagram.com/riannebil_groenenstyling/"><i className="fab fa-instagram"></i>  Instagram</a></div>
                <div><a href="https://nl.pinterest.com/rb9078/"><i className="fab fa-pinterest"></i>  Pinterest</a></div>
                <div onClick={this.formRenderHandler}><a><i className="fas fa-envelope"></i>  Stuur een berichtje</a></div>
              </span>
            </div>
          </footer>
        </div>
      </>
    );
  }
}

export default Home;
